import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { httpClient } from "../services/HttpClient";
import * as yup from "yup";
// import MaterialTable from "material-table";
import MaterialTable from "material-table";
import DeleteIcon from "@mui/icons-material/Delete";
import { tableIcons } from "../utils/tableIcons";
import notify from "../utils/notification";

export default function RegisterCategory() {
  const [categories, setcategories] = useState([]);
  const [foodImage, setFoodImage] = useState(null);
  const initialValues = {
    category: "",
  };
  let schema = yup.object().shape({
    category: yup.string().required(),
  });
  const columns = [
    { title: "Category", field: "category" },
    {
      title: "image",
      editable: false,
      field: "image",
      render: (item) => {
        return (
          <img
            src={process.env.REACT_APP_BASE_URL + "file/" + item.image}
            alt=""
            border="3"
            height="50"
            width="80"
          />
        );
      },
    },
  ];
  const fetchCategories = () => {
    httpClient
      .GET("category")
      .then((resp) => setcategories(resp.data))
      .catch((err) => console.log("Error in fetching data"));
  };
  useEffect(() => {
    fetchCategories();
  }, []);
  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",

          // position: "sticky",
        }}
        className="mt-4 row"
      >
        <div
          style={{ width: "30%", marginTop: "80px" }}
          // className="col-sm-6 col-md-8"
        >
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values, props) => {
              let image = [];
              image.push(foodImage);
              httpClient
                .UPLOAD("post", "category", values, image)
                .then((resp) => {
                  props.resetForm();
                  fetchCategories();
                })
                .catch((err) => {
                  console.log("Error occurred in updating category");
                });
            }}
          >
            {(values) => (
              <Form>
                <div className="  d-flex justify-content-center">
                  <div className="form-data">
                    <div className="forms-inputs borderReg mb-4">
                      <span style={{ color: "#007782" }}>Category</span>
                      <Field
                        autoComplete="off"
                        type="text"
                        name="category"
                        style={{ width: "100%" }}
                      />
                      <ErrorMessage
                        name="category"
                        render={(msg) => <div className="invalid">{msg}</div>}
                      />
                    </div>
                    <div className="forms-inputs mb-4">
                      <span style={{ color: "#007782" }}>Category Image</span>
                      <input
                        autoComplete="off"
                        type="file"
                        name="image"
                        className="mt-4"
                        required
                        onChange={(e) => setFoodImage(e.target.files[0])}
                      />
                    </div>
                    <div className="mb-3">
                      <button
                        className="btn  w-100"
                        style={{ backgroundColor: "#007782" }}
                        type="submit"
                      >
                        Save Category
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div>
          {" "}
          {foodImage ? (
            <div>
              <img
                src={URL.createObjectURL(foodImage)}
                style={{
                  // position: "relative",
                  right: "50%",
                  width: "30rem",
                  height: "30rem",
                }}
              ></img>
            </div>
          ) : null}
        </div>
        <div
          style={{
            // position: "absolute",
            left: "1%",
            width: "70%",
            top: "100%",
            // sty
          }}
          // className="col-6 col-md-4"
        >
          <MaterialTable
            columns={columns}
            data={categories}
            title="Categories Details"
            actions={[
              (rowData) => ({
                icon: DeleteIcon,
                tooltip: "Delete Category",
                onClick: (event, rowData) => {
                  httpClient
                    .DELETE(`category/${rowData._id}`)
                    .then((resp) => {
                      console.log("item deleted successfully");
                      fetchCategories();
                    })
                    .catch((err) => {
                      console.log("Error deleting item");
                    });
                },
              }),
            ]}
            options={{
              paging: true,
              exportButton: true,
              pageSize: 5,
              showFirstLastPageButtons: false,
              actionsColumnIndex: -1,
              search: true,
              searchFieldStyle: {
                backgroundColor: "",
              },

              headerStyle: {
                backgroundColor: "#007782",
                color: "#fff",
                fontSize: "18px",
                fontWeight: "bold",
              },
            }}
          />
        </div>
      </div>
    </>
  );
}
