import React from "react";
import "./Navbars.css";
import { useNavigate } from "react-router";
import LogoutIcon from "@mui/icons-material/Logout";
import logo from "../Images/logo4.PNG";

const Navbars = () => {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };
  return (
    <>
      <div className="navbars">
        <img src={logo} className="logo" alt="Logo" />
        <p className="titles">K-pop Restaurant </p>
        <p className="logout" onClick={logout}>
          <LogoutIcon />
          Logout{" "}
        </p>
      </div>
    </>
  );
};

export default Navbars;
