import "./login.css";
import React, { useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router";
import { httpClient } from "../services/HttpClient";
import notify from "../utils/notification";
export default function Login() {
  const initialValues = {
    email: "",
    password: "",
  };
  let schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  return (
    <div className="loginbody  ">
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values, props) => {
          setLoading(true);
          httpClient
            .POST("auth/login", values)
            .then((resp) => {
              localStorage.setItem("token", resp.data.token);
              navigate("/home");
            })
            .catch((err) => {
              notify(err.response.data.msg3.msg, "error");
              // window.alert(err.response.data.msg3.msg);
              // if(err.response.data)
              // console.log("Error is", err.response);
            })
            .finally(() => {
              setLoading(false);
            });
          console.log("values are", values);
        }}
      >
        {(values) => (
          <Form>
            <div className="container mt-5 formBody ">
              <div className="row d-flex justify-content-center">
                <div className="col-md-6">
                  <div className="card px-5 py-5 " id="form1">
                    <div className="form-data">
                      <p className="fs-2 text-center">K-pop Admin</p>
                      <div className="forms-inputs mb-4">
                        <span>Email or username</span>
                        <Field autoComplete="off" type="text" name="email" />
                        <ErrorMessage
                          name="email"
                          render={(msg) => (
                            <div className="invalid errorMsg">{msg}</div>
                          )}
                        />
                      </div>
                      <div className="forms-inputs mb-4">
                        <span>Password</span>
                        <Field
                          autoComplete="off"
                          type="password"
                          name="password"
                        />
                        <ErrorMessage
                          name="password"
                          render={(msg) => (
                            <div className="invalid errorMsg">{msg}</div>
                          )}
                          c
                        />
                      </div>
                      <div className="mb-3">
                        <button className="btn bgColor w-100" type="submit">
                          Login
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
