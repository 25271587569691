import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";
import Sidebar from "./sidebar";
import NaviBar from "./NaviBar";

export default function Dashboard() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, []);
  return (
    <div>
      <div className="bar">
        <NaviBar />
        <Sidebar></Sidebar>
      </div>

      <div className="outlet-value">
        <Outlet></Outlet>
      </div>
    </div>
  );
}
