import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import Login from "../auth/Login";
import PageNotFound from "../Pagenotfound/PageNotFound";
import Dashboard from "../dashboard/dashboard";
import Sidebar from "../dashboard/sidebar";
import UpdateFood from "../dashboard/updateFood";
import DashboardHome from "../dashboard/DashboardHome";
import RegisterCategory from "../dashboard/registerCategory";
import RegisterSubCategory from "../dashboard/SubCategory";
import Menu from "../menu/Menu";
export default function MainRouter() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Menu />} />
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<Dashboard />}>
            <Route path="" element={<DashboardHome />} />
            <Route path="update-food" element={<UpdateFood />} />
            <Route path="category" element={<RegisterCategory />} />
            <Route path="subcategory" element={<RegisterSubCategory />} />
          </Route>
          <Route></Route>
          <Route path="*" element={<PageNotFound></PageNotFound>}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
