import Web from "./menu/web.js";
import MainRouter from "./routes/routes";

function App() {
  return (
    <div className="App">
      <Web></Web>
    </div>
  );
}

export default App;
