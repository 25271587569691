import App from "../App";
import "./web.css";

function Web() {
  return (
    <>
      <div>
        <div>
          <p className="text-bg-dark p-3">DRINKS</p>
        </div>
        <div
          id="carouselExampleControls"
          className="carousel carousel-dark slide"
          data-bs-ride="carousel"
        >
          <div className="">
            <div className="carousel-item active">
              {/* <div className="cards-wrapper">
                <div className="card  width text-bg-dark p-3">
                  <img
                    src="./webImage/softdink.jpg"
                    className="imageWidth"
                    alt="burger.webp"
                  />

                  <div className="card-body">
                    <h5 className="card-title">Hard Drinks1</h5>

                    <div className="row align-items-start">
                      <div className="col-9">
                        <p className="fs-5">Hard Drinks Items</p>
                        <div className="mb-2">
                          <img
                            src="./webImage/softdink.jpg"
                            className="res"
                            alt="..."
                          />
                          Red Label
                        </div>
                        <div>
                          <img
                            src="./webImage/softdink.jpg"
                            className="res"
                            alt="..."
                          />
                          Red Label
                        </div>
                      </div>

                      <div className="col">
                        <p className="fs-5">Price</p>
                        <div className="mb-5">
                          <p>Rs 8000</p>
                        </div>
                        <div className="mt-2">
                          <p>Rs 8000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card text-bg-dark p-3">
                  <img src="./webImage/softdink.jpg" alt="burger.webp" />

                  <div className="card-body">
                    <h5 className="card-title">Soft Drinks</h5>
                    <div className="row align-items-start">
                      <div className="col-9">
                        <p className="fs-5">Hard Drinks Items</p>
                        <div className="mb-2">
                          <img
                            src="./webImage/softdink.jpg"
                            className="res"
                            alt="..."
                          />
                          Red Label
                        </div>
                        <div>
                          <img
                            src="./webImage/softdink.jpg"
                            className="res"
                            alt="..."
                          />
                          Red Label
                        </div>
                      </div>

                      <div className="col">
                        <p className="fs-5">Price</p>
                        <div className="mb-5">
                          <p>Rs 8000</p>
                        </div>
                        <div className="mt-2">
                          <p>Rs 8000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card text-bg-dark p-3">
                  <img src="./webImage/softdink.jpg" alt="burger.webp" />

                  <div className="card-body">
                    <h5 className="card-title">Cold Drinks</h5>
                    <div className="row align-items-start">
                      <div className="col-9">
                        <p className="fs-5">Hard Drinks Items</p>
                        <div className="mb-2">
                          <img
                            src="./webImage/softdink.jpg"
                            className="res"
                            alt="..."
                          />
                          Red Label
                        </div>
                        <div>
                          <img
                            src="./webImage/softdink.jpg"
                            className="res"
                            alt="..."
                          />
                          Red Label
                        </div>
                      </div>

                      <div className="col">
                        <p className="fs-5 ">Price</p>
                        <div className="mb-5">
                          <p>Rs 8000</p>
                        </div>
                        <div className="mt-2">
                          <p>Rs 8000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div className="carousel-item active">
            <div className="cards-wrapper">
              <div className="card text-bg-dark  p-3">
                <img
                  src="./webImage/softdink.jpg"
                  className=""
                  alt="burger.webp"
                />

                <div className="card-body ">
                  <h5 className="card-title">Hard Drinks2</h5>

                  <div className="row align-items-start">
                    <div className="col-9">
                      <p className="fs-5">Hard Drinks Items</p>
                      <div className="mb-2">
                        <img
                          src="./webImage/softdink.jpg"
                          className="res"
                          alt="..."
                        />
                        Red Label
                      </div>
                      <div>
                        <img
                          src="./webImage/softdink.jpg"
                          className="res"
                          alt="..."
                        />
                        Red Label
                      </div>
                    </div>

                    <div className="col">
                      <p className="fs-5">Price</p>
                      <div className="mb-5">
                        <p>Rs 8000</p>
                      </div>
                      <div className="mt-2">
                        <p>Rs 8000</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card text-bg-dark p-3">
                <img
                  src="./webImage/softdink.jpg"
                  alt="burger.webp"
                  className="respo"
                />

                <div className="card-body">
                  <h5 className="card-title">Soft Drinks</h5>
                  <div className="row align-items-start">
                    <div className="col-9">
                      <p className="fs-5">Hard Drinks Items</p>
                      <div className="mb-2">
                        <img
                          src="./webImage/softdink.jpg"
                          className="res"
                          alt="..."
                        />
                        Red Label
                      </div>
                      <div>
                        <img
                          src="./webImage/softdink.jpg"
                          className="res"
                          alt="..."
                        />
                        Red Label
                      </div>
                    </div>

                    <div className="col">
                      <p className="fs-5">Price</p>
                      <div className="mb-5">
                        <p>Rs 8000</p>
                      </div>
                      <div className="mt-2">
                        <p>Rs 8000</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card text-bg-dark p-3">
                <img src="./webImage/softdink.jpg" alt="burger.webp" />

                <div className="card-body">
                  <h5 className="card-title">Cold Drinks</h5>
                  <div className="row align-items-start">
                    <div className="col-9">
                      <p className="fs-5">Hard Drinks Items</p>
                      <div className="mb-2">
                        <img
                          src="./webImage/softdink.jpg"
                          className="res"
                          alt="..."
                        />
                        Red Label
                      </div>
                      <div>
                        <img
                          src="./webImage/softdink.jpg"
                          className="res"
                          alt="..."
                        />
                        Red Label
                      </div>
                    </div>

                    <div className="col">
                      <p className="fs-5">Price</p>
                      <div className="mb-5">
                        <p>Rs 8000</p>
                      </div>
                      <div className="mt-2">
                        <p>Rs 8000</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="cards-wrapper">
              <div className="card text-bg-dark p-3">
                <img src="./webImage/softdink.jpg" alt="burger.webp" />

                <div className="card-body">
                  <h5 className="card-title">Hard Drinks</h5>

                  <div className="row align-items-start">
                    <div className="col-9">
                      <p className="fs-5">Hard Drinks Items</p>
                      <div className="mb-2">
                        <img
                          src="./webImage/softdink.jpg"
                          className="res"
                          alt="..."
                        />
                        Red Label
                      </div>
                      <div>
                        <img
                          src="./webImage/softdink.jpg"
                          className="res"
                          alt="..."
                        />
                        Red Label
                      </div>
                    </div>

                    <div className="col">
                      <p className="fs-5">Price</p>
                      <div className="mb-5">
                        <p>Rs 8000</p>
                      </div>
                      <div className="mt-2">
                        <p>Rs 8000</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card text-bg-dark p-3">
                <img src="./webImage/softdink.jpg" alt="burger.webp" />

                <div className="card-body">
                  <h5 className="card-title">Soft Drinks</h5>
                  <div className="row align-items-start">
                    <div className="col-9">
                      <p className="fs-5">Hard Drinks Items</p>
                      <div className="mb-2">
                        <img
                          src="./webImage/softdink.jpg"
                          className="res"
                          alt="..."
                        />
                        Red Label
                      </div>
                      <div>
                        <img
                          src="./webImage/softdink.jpg"
                          className="res"
                          alt="..."
                        />
                        Red Label
                      </div>
                    </div>

                    <div className="col">
                      <p className="fs-5">Price</p>
                      <div className="mb-5">
                        <p>Rs 8000</p>
                      </div>
                      <div className="mt-2">
                        <p>Rs 8000</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card text-bg-dark p-3">
                <img src="./webImage/softdink.jpg" alt="burger.webp" />

                <div className="card-body">
                  <h5 className="card-title">Cold Drinks</h5>
                  <div className="row align-items-start">
                    <div className="col-9">
                      <p className="fs-5">Hard Drinks Items</p>
                      <div className="mb-2">
                        <img
                          src="./webImage/softdink.jpg"
                          className="res"
                          alt="..."
                        />
                        Red Label
                      </div>
                      <div>
                        <img
                          src="./webImage/softdink.jpg"
                          className="res"
                          alt="..."
                        />
                        Red Label
                      </div>
                    </div>

                    <div className="col">
                      <p className="fs-5">Price</p>
                      <div className="mb-5">
                        <p>Rs 8000</p>
                      </div>
                      <div className="mt-2">
                        <p>Rs 8000</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="cards-wrapper">
              <div className="card text-bg-dark p-3">
                <img src="./webImage/softdink.jpg" alt="burger.webp" />

                <div className="card-body">
                  <h5 className="card-title">Hard Drinks</h5>

                  <div className="row align-items-start">
                    <div className="col-9">
                      <p className="fs-5">Hard Drinks Items</p>
                      <div className="mb-2">
                        <img
                          src="./webImage/softdink.jpg"
                          className="res"
                          alt="..."
                        />
                        Red Label
                      </div>
                      <div>
                        <img
                          src="./webImage/softdink.jpg"
                          className="res"
                          alt="..."
                        />
                        Red Label
                      </div>
                    </div>

                    <div className="col">
                      <p className="fs-5">Price</p>
                      <div className="mb-5">
                        <p>Rs 8000</p>
                      </div>
                      <div className="mt-2">
                        <p>Rs 8000</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card text-bg-dark p-3">
                <img src="./webImage/softdink.jpg" alt="burger.webp" />

                <div className="card-body">
                  <h5 className="card-title">Soft Drinks</h5>
                  <div className="row align-items-start">
                    <div className="col-9">
                      <p className="fs-5">Hard Drinks Items</p>
                      <div className="mb-2">
                        <img
                          src="./webImage/softdink.jpg"
                          className="res"
                          alt="..."
                        />
                        Red Label
                      </div>
                      <div>
                        <img
                          src="./webImage/softdink.jpg"
                          className="res"
                          alt="..."
                        />
                        Red Label
                      </div>
                    </div>

                    <div className="col">
                      <p className="fs-5">Price</p>
                      <div className="mb-5">
                        <p>Rs 8000</p>
                      </div>
                      <div className="mt-2">
                        <p>Rs 8000</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card text-bg-dark p-3">
                <img src="./webImage/softdink.jpg" alt="burger.webp" />

                <div className="card-body">
                  <h5 className="card-title">Cold Drinks</h5>
                  <div className="row align-items-start">
                    <div className="col-9">
                      <p className="fs-5">Hard Drinks Items</p>
                      <div className="mb-2">
                        <img
                          src="./webImage/softdink.jpg"
                          className="res"
                          alt="..."
                        />
                        Red Label
                      </div>
                      <div>
                        <img
                          src="./webImage/softdink.jpg"
                          className="res"
                          alt="..."
                        />
                        Red Label
                      </div>
                    </div>

                    <div className="col">
                      <p className="fs-5">Price</p>
                      <div className="mb-5">
                        <p>Rs 8000</p>
                      </div>
                      <div className="mt-2">
                        <p>Rs 8000</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
}

export default Web;
