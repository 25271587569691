import React from "react";
import { useNavigate } from "react-router";
import LogoutIcon from "@mui/icons-material/Logout";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";
import DinnerDiningOutlinedIcon from "@mui/icons-material/DinnerDiningOutlined";
import "./dashboard.css";
import logo from "../Images/logo3.jpg";
import { useLocation } from "react-router";
import { NavLink, Link } from "react-router-dom";

import DashboardIcon from "@mui/icons-material/Dashboard";
export default function Sidebar() {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };
  // const onClickProperites = () => {
  //   navigate("/home/update-food");
  // };
  const Header = () => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
  };

  return (
    <div
      className="w3-sidebar sidebars w3-bar-block "
      style={{ width: "13%", backgroundColor: "#007782", marginTop: "82px" }}
    >
      <h3 className="title" onClick={() => navigate("/home")}>
        {/* <img src={logo} className="logo" alt="Logo" /> */}
        <p className="active hover visible" style={{ cursor: "pointer" }}>
          <DashboardIcon className="dashIcon" />
          Dashboard
        </p>
      </h3>
      <div classNmae="myDiv">
        <div
          style={{ cursor: "pointer" }}
          className="w3-bar-item title  hover visible"
          onClick={() => navigate("/home/category")}
        >
          <AddBoxOutlinedIcon /> Register Categories
        </div>

        <div
          style={{ cursor: "pointer" }}
          className="w3-bar-item title  hover  "
          onClick={() => navigate("/home/subcategory")}
        >
          <AddToPhotosOutlinedIcon /> Register SubCategories
        </div>
        <div
          style={{ cursor: "pointer" }}
          className="w3-bar-item title  hover "
          onClick={() => navigate("/home/update-food")}
        >
          <DinnerDiningOutlinedIcon />
          Food Menu
        </div>
      </div>

      <div
        className="title"
        style={{ position: "absolute", bottom: "3px", cursor: "pointer" }}
        onClick={logout}
      >
        Logout <LogoutIcon />
      </div>
    </div>
  );
}
