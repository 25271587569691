import React from "react";
import "./DashboardHome.js";
import "./DashboardHome.css";

export default function DashboardHome() {
  return (
    <div>
      <navbar className="dashPage"></navbar>
    </div>
  );
}
