import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import MaterialTable from "material-table";
import { httpClient } from "../services/HttpClient";
import * as yup from "yup";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { tableIcons } from "../utils/tableIcons";
import { Edit } from "@material-ui/icons";
import notify from "../utils/notification";
export default function UpdateFood() {
  const [food, setFood] = useState([]);
  const [foodImage, setFoodImage] = useState(null);
  const [categories, setcategories] = useState([]);
  const [subCategory, setSubcategory] = useState([]);
  const columns = [
    { title: "Food Name", field: "foodName" },
    { title: "foodCategory", field: "foodCategory", editable: false },
    { title: "Price", field: "price" },
    { title: "Sub Category", field: "subCategory", editable: false },
    { title: "Description", field: "description" },
    {
      title: "Image",
      field: "image",
      editable: false,
      render: (item) => {
        return (
          <img
            src={process.env.REACT_APP_BASE_URL + "file/" + item.image}
            alt=""
            border="3"
            height="100"
            width="100"
          />
        );
      },
    },
  ];
  const initialValues = {
    foodName: "",
    foodCategory: "",
    price: "",
    subCategory: "",
    description: "",
  };
  let schema = yup.object().shape({
    foodName: yup.string().required(),
    foodCategory: yup.string().required(),
    price: yup.string().required(),
    description: yup.string().required(),
    subCategory: yup.string().required(),
  });
  const fetchFood = () => {
    httpClient
      .GET("menu")
      .then((resp) => {
        let data = resp.data.map((item, index) => {
          item.foodCategory = item.foodCategory.category;
          item.subCategory = item.subCategory.subcategory;
          return item;
        });
        console.log("data is", data);
        setFood(data);
      })
      .catch((err) => {
        console.log("error is", err);
      });
  };
  const fetchCategories = () => {
    httpClient
      .GET("category")
      .then((resp) => setcategories(resp.data))
      .catch((err) => console.log("Error in fetching data"));
  };
  const fetchSubCategoryOfCategory = (value) => {
    httpClient
      .POST("subcategory/getsubcategory", { category: value })
      .then((resp) => setSubcategory(resp.data))
      .catch((err) => console.log("Error is", err.response));
  };
  useEffect(() => {
    fetchFood();
    fetchCategories();
  }, []);
  return (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
        className="mt-4"
      >
        <div style={{ width: "50%", marginTop: "20px" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values, props) => {
              let image = [];
              image.push(foodImage);
              console.log("values", values, image);
              httpClient
                .UPLOAD("post", "menu", values, image)
                .then((resp) => {
                  notify("Food Added Successfully", "success");
                  props.resetForm();
                  fetchFood();
                  setFoodImage(null);
                })
                .catch((err) => {
                  console.log("Error is", err.response);
                });
            }}
          >
            {(values) => (
              <Form>
                {console.log("values are", values.values)}
                <div className="row d-flex justify-content-center">
                  <div className="form-data">
                    <div className="forms-inputs mb-4">
                      <span>Category</span>
                      <Field
                        autoComplete="off"
                        type="text"
                        as="select"
                        name="foodCategory"
                        style={{ width: "100%", height: "40px" }}
                        onChange={(e) => {
                          values.setFieldValue("foodCategory", e.target.value);
                          fetchSubCategoryOfCategory(e.target.value);
                        }}
                      >
                        <option value="">Select category</option>
                        {categories &&
                          categories.map((item, index) => {
                            return (
                              <option value={item._id}>{item.category}</option>
                            );
                          })}
                      </Field>
                      <ErrorMessage
                        name="foodCategory"
                        render={(msg) => <div className="invalid">{msg}</div>}
                      />
                    </div>
                    <div className="forms-inputs mb-4">
                      <span>Sub Category</span>
                      <Field
                        autoComplete="off"
                        type="text"
                        as="select"
                        name="subCategory"
                        style={{ width: "100%", height: "40px" }}
                      >
                        <option value="">Select Subcategory</option>
                        {subCategory.length &&
                          subCategory.map((item, index) => (
                            <option value={item._id}>{item.subcategory}</option>
                          ))}
                      </Field>
                      <ErrorMessage
                        name="subCategory"
                        render={(msg) => <div className="invalid">{msg}</div>}
                      />
                    </div>
                    <div className="forms-inputs mb-4">
                      <span>Food Name</span>
                      <Field
                        autoComplete="off"
                        type="text"
                        name="foodName"
                        style={{ width: "100%" }}
                      />
                      <ErrorMessage
                        name="foodName"
                        render={(msg) => <div className="invalid">{msg}</div>}
                      />
                    </div>
                    <div className="forms-inputs mb-4">
                      <span>Description</span>
                      <Field
                        autoComplete="off"
                        type="text"
                        name="description"
                        style={{ width: "100%" }}
                      />
                      <ErrorMessage
                        name="description"
                        render={(msg) => <div className="invalid">{msg}</div>}
                      />
                    </div>
                    <div className="forms-inputs mb-4">
                      <span>price</span>
                      <Field autoComplete="off" type="number" name="price" />
                      <ErrorMessage
                        name="price"
                        render={(msg) => <div className="invalid">{msg}</div>}
                      />
                    </div>
                    <div className="forms-inputs mb-4">
                      <span>Food Image</span>
                      <input
                        autoComplete="off"
                        type="file"
                        name="image"
                        className="mt-4"
                        required
                        onChange={(e) => setFoodImage(e.target.files[0])}
                      />
                    </div>
                    <div className="mb-3">
                      <button
                        className="btn w-100 "
                        style={{ backgroundColor: "#007782" }}
                        type="submit"
                      >
                        Save Food
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div>
          {" "}
          {foodImage ? (
            <div>
              <img
                src={URL.createObjectURL(foodImage)}
                style={{
                  position: "relative",
                  right: "50%",
                  width: "30rem",
                  height: "30rem",
                }}
              ></img>
            </div>
          ) : null}
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          left: "1%",
          width: "100%",
        }}
      >
        <MaterialTable
          icons={tableIcons}
          columns={columns}
          data={food}
          title="Food Details"
          editable={{
            // onRowAdd: (newData) =>
            //   new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //       httpClient
            //         .POST("common/insert-business-types", newData, false, true)
            //         .then((resp) => {
            //           fetchCategories();
            //         })
            //         .catch((err) => {
            //           ErrorHandler(err);
            //         });
            //       resolve();
            //     }, 1000);
            //   }),
            onRowUpdate: (data) => {
              return new Promise((resolve, reject) => {
                httpClient
                  .PUT(`menu/${data._id}`, data)
                  .then(() => {
                    notify("Food Updated Successfully", "success");
                    fetchFood();
                  })
                  .catch((err) => notify("Something went wrong", "error"));
                resolve();
                // setTimeout(() => {
                //   httpClient
                //     .POST("common/insert-business-types", newData, false, true)
                //     .then((resp) => {
                //       fetchCategories();
                //     })
                //     .catch((err) => {
                //       ErrorHandler(err);
                //     });
                //   resolve();
                // }, 1000);
              });
            },
          }}
          actions={[
            (rowData) => ({
              icon: DeleteIcon,
              tooltip: "Delete Food",
              onClick: (event, rowData) => {
                httpClient
                  .DELETE(`menu/${rowData._id}`)
                  .then((resp) => {
                    notify("Food deleted Successfully", "success");
                    fetchFood();
                  })
                  .catch((err) => {
                    notify("Error deleting message", "error");
                  });
              },
            }),
          ]}
          options={{
            paging: true,
            exportButton: true,
            pageSize: 5,
            showFirstLastPageButtons: false,
            actionsColumnIndex: -1,
            search: true,
            headerStyle: {
              backgroundColor: "#007782",
              color: "#fff",
              fontSize: "18px",
              fontWeight: "bold",
            },
          }}
        />
      </div>
    </div>
  );
}
