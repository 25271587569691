import React from "react";
import "./toogleSwitch.css";
  
const ToggleSwitch = ({ label,setDarkTheme ,darkTheme}) => {
  const setTheme=(checkedValue)=>{
    if(darkTheme){
      return setDarkTheme(false)
    }
    setDarkTheme(true)
  }
  return (
    <div>
      {label}{" "}
      <div className="toggle-switch">
        <input type="checkbox" className="checkbox" 
               name={label} id={label} onChange={(e)=>setTheme(e.target.checked)}/>
        <label className="label" htmlFor={label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
};
  
export default ToggleSwitch;