import { ErrorMessage, Field, Form, Formik } from "formik";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { httpClient } from "../services/HttpClient";
import DeleteIcon from "@mui/icons-material/Delete";
import { tableIcons } from "../utils/tableIcons";
import notify from "../utils/notification";

export default function RegisterSubCategory() {
  const [subCategory, setSubcategory] = useState([]);
  const [categories, setcategories] = useState([]);
  const [foodImage, setFoodImage] = useState(null);
  const initialValues = {
    category: "",
    subCategory: "",
    image: "",
    price: "",
  };
  let schema = yup.object().shape({
    category: yup.string().required(),
    subCategory: yup.string().required(),
    price: yup.string().required(),
  });
  const columns = [
    { title: "Category", field: "category", editable: false },
    { title: "Sub Category", field: "subcategory" },
    { title: "Price", field: "price" },
    {
      title: "image",
      editable: false,
      field: "image",
      render: (item) => {
        return (
          <img
            src={process.env.REACT_APP_BASE_URL + "file/" + item.image}
            alt=""
            border="3"
            height="40"
            width="80"
          />
        );
      },
    },
  ];
  const fetchSubCategories = () => {
    httpClient
      .GET("subcategory")
      .then((resp) => {
        let data = resp.data.map((item, index) => {
          item.category = item.category.category;
          return item;
        });
        setSubcategory(data);
      })
      .catch((err) => {
        console.log("Error in fetching sub categories");
      });
  };
  const fetchCategories = () => {
    httpClient
      .GET("category")
      .then((resp) => setcategories(resp.data))
      .catch((err) => console.log("Error in fetching data"));
  };
  useEffect(() => {
    fetchSubCategories();
    fetchCategories();
  }, []);
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
      className="mt-4"
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
        className="mt-4"
      >
        <div style={{ width: "50%", marginTop: "" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values, props) => {
              let image = [];
              image.push(foodImage);
              values.subcategory = values.subCategory;
              httpClient
                .UPLOAD("post", "subcategory", values, image)
                .then((resp) => {
                  notify("Subcategory added successfully", "success");

                  props.resetForm();
                  fetchSubCategories();
                  setFoodImage(null);
                })
                .catch((err) => {
                  console.log("Error occurred in updating category");
                });
            }}
          >
            {(values) => (
              <Form>
                <div className="row d-flex justify-content-center">
                  <div className="form-data">
                    <div className="forms-inputs mb-4">
                      <span>Category</span>
                      <Field
                        autoComplete="off"
                        type="text"
                        as="select"
                        name="category"
                        style={{ width: "100%", height: "40px" }}
                      >
                        <option value="">Select category</option>
                        {categories &&
                          categories.map((item, index) => (
                            <option value={item._id}>{item.category}</option>
                          ))}
                      </Field>
                      <ErrorMessage
                        name="category"
                        render={(msg) => <div className="invalid">{msg}</div>}
                      />
                    </div>
                    <div className="forms-inputs mb-4">
                      <span>sub Category</span>
                      <Field
                        autoComplete="off"
                        type="text"
                        name="subCategory"
                        style={{ width: "100%" }}
                      />
                      <ErrorMessage
                        name="subCategory"
                        render={(msg) => <div className="invalid">{msg}</div>}
                      />
                    </div>
                    <div className="forms-inputs mb-4">
                      <span>price</span>
                      <Field
                        autoComplete="off"
                        type="number"
                        name="price"
                        style={{ width: "100%" }}
                      />
                      <ErrorMessage
                        name="price"
                        render={(msg) => <div className="invalid">{msg}</div>}
                      />
                    </div>
                    <div className="forms-inputs mb-4">
                      <span>Food Image</span>
                      <input
                        autoComplete="off"
                        type="file"
                        name="image"
                        className="mt-4"
                        required
                        onChange={(e) => setFoodImage(e.target.files[0])}
                      />
                    </div>
                    <div className="mb-3">
                      <button
                        className="btn  w-100"
                        type="submit"
                        style={{ backgroundColor: "#007782" }}
                      >
                        Save Category
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div>
          {" "}
          {foodImage ? (
            <div>
              <img
                src={URL.createObjectURL(foodImage)}
                style={{
                  position: "relative",
                  right: "50%",
                  width: "30rem",
                  height: "30rem",
                }}
              ></img>
            </div>
          ) : null}
        </div>
      </div>

      <div>
        <MaterialTable
          icons={tableIcons}
          columns={columns}
          data={subCategory}
          title="Categories Details"
          editable={{
            onRowUpdate: (data) => {
              return new Promise((resolve, reject) => {
                console.log("data are", data);
                httpClient
                  .PUT(`/subcategory/${data._id}`, data)
                  .then(() => {
                    notify("Subcategory Updated Successfully", "success");
                    fetchSubCategories();
                  })
                  .catch((err) => notify("Something went wrong", "error"));
                resolve();
              });
            },
          }}
          actions={[
            (rowData) => ({
              icon: DeleteIcon,
              tooltip: "Delete Category",
              onClick: (event, rowData) => {
                httpClient
                  .DELETE(`subcategory/${rowData._id}`)
                  .then((resp) => {
                    notify("Sub category deleted successfully", "success");
                    fetchSubCategories();
                  })
                  .catch((err) => {
                    notify(err.response.data.msg3, "error");
                    console.log("Error deleting item");
                  });
              },
            }),
          ]}
          options={{
            paging: true,
            exportButton: true,
            pageSize: 5,
            showFirstLastPageButtons: false,
            actionsColumnIndex: -1,
            search: true,
            headerStyle: {
              backgroundColor: "#007782",
              color: "#fff",
              fontSize: "18px",
              fontWeight: "bold",
            },
          }}
        />
      </div>
    </div>
  );
}
