import React, { useEffect, useState, useCallback, useRef } from "react";
import { httpClient } from "../services/HttpClient";
import "./Menu.css";
import Modal from "react-modal";
import fishes from "../Images/image2.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import topicon from "../Images/expand.png";
import closeButton from "../Images/close.png";
import ToggleSwitch from "../auth/toggleButton/ToggleSwitch";
import { FaCaretSquareUp } from "react-icons/fa";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import closeButton2 from "../Images/closeButton.png";

import { Controlled as ControlledZoom } from "react-medium-image-zoom";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

export default function Menu() {
  const [modalOpen, setModalOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [finalFood, setFinalFood] = useState([]);
  console.log("final food is", finalFood);

  const [categoryDescription, setCategoryDescription] = useState(null);
  const [darkTheme, setDarkTheme] = useState(true);
  const [visible, setVisible] = useState(false);
  const { width } = useWindowDimensions();
  const categoryRef = useRef(null);
  const [offset, setOffset] = useState(0);

  const fetchFood = () => {
    httpClient
      .GET("category")
      .then((resp) => {
        let categories = resp.data;
        setCategories(categories);
        httpClient
          .GET("menu")
          .then((resp) => {
            let categoricalData = categories.map((category, categoryIndex) => {
              let categoryData = resp.data.filter((item, index) => {
                return item.foodCategory.category === category.category;
              });
              return { category: category.category, data: categoryData };
            });
            let finalData = categoricalData.map((category, categoryIndex) => {
              if (!category.data.length) {
                return;
              } else {
                let subCata = [];
                category.data.map((item, index) => {
                  let isIncluded = subCata.some(
                    (cataItem) =>
                      item.subCategory.subcategory === cataItem.subcats
                  );
                  if (!isIncluded) {
                    subCata.push({
                      subcats: item.subCategory.subcategory,
                      price: item.subCategory.price,
                      image: item.subCategory.image,
                    });
                  }
                });
                let subCatFinalData = subCata.map((subcat, subCatIndex) => {
                  let subcatDataFiltered = category.data.filter(
                    (food, foodIndex) =>
                      food.subCategory.subcategory === subcat.subcats
                  );
                  return {
                    subcategory: subcat.subcats,
                    price: subcat.price,
                    food: subcatDataFiltered,
                    image: subcat.image,
                  };
                });
                category.data = subCatFinalData;
                return category;
              }
            });

            // Exchange the position of hard drinks and soft drinks
            // Hard drinks at first and soft drinks at second
            let fromIndex = 4;
            let toIndex = 5;
            const softDrinks = finalData.splice(fromIndex, 1)[0];
            finalData.splice(toIndex, 0, softDrinks);
            setFinalFood(finalData);
            // End of the drinks switching
          })
          .catch((err) => console.log("err", err.response));
        setCategories(resp.data);
      })
      .catch((err) => console.log("err", err.response));
  };
  useEffect(() => {
    fetchFood();
  }, []);
  useEffect(() => {
    categoryRef.current = document.querySelector(".category-sticky");
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div>
      <div className={darkTheme ? "darkTheme" : "lightTheme"}>
        <div className={darkTheme ? "HeaderMain" : "HeaderMainLight"}>
          <img
            src={"/images/logo.png"}
            alt="Logo Image"
            className="logoImage"
          />
          <h3
            className={darkTheme ? "ResturantName" : "ResturantNameLightTheme"}
          >
            K-POP Restaurant
          </h3>
          <div className="ToogleSwitchStyle">
            <ToggleSwitch
              label="."
              setDarkTheme={setDarkTheme}
              darkTheme={darkTheme}
            />
          </div>
        </div>
        {!categories.length ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div class="loader"></div>
          </div>
        ) : (
          !modalOpen && (
            <>
              <div
                className="category-sticky"
                style={
                  offset > 208 && darkTheme
                    ? {
                        position: "fixed",
                        zIndex: "10",
                        top: "-70px",
                        background: "rgb(27, 27, 26)",
                        height: "188px",
                      }
                    : offset > 208 && !darkTheme
                    ? {
                        position: "fixed",
                        zIndex: "10",
                        top: "-70px",
                        background: "#fff",
                        height: "188px",
                      }
                    : { position: "relative" }
                }
              >
                {categories && (
                  <div className="categories">
                    <OwlCarousel className="owl-theme" {...options}>
                      {categories.map((item, index) => {
                        return (
                          <a href={`#${item.category}`}>
                            <div
                              className="CategoriesItem1"
                              style={{ marginLeft: "px" }}
                            >
                              <div>
                                <img
                                  // src={fishes}
                                  src={
                                    REACT_APP_BASE_URL + "file/" + item.image
                                  }
                                  alt="item3"
                                  className="imageSize"
                                />
                              </div>

                              <p className="CategoryName">{item.category}</p>
                            </div>
                          </a>
                        );
                      })}
                    </OwlCarousel>
                  </div>
                )}
              </div>
              <div
                style={
                  offset > 208
                    ? { marginTop: "19rem" }
                    : { position: "relative" }
                }
              >
                {finalFood &&
                  finalFood.map((item, index) => {
                    if (item) {
                      return (
                        <div id={item.category}>
                          <div
                            className={
                              darkTheme
                                ? "categoryHeadingArea"
                                : "categoryHeadingAreaLight"
                            }
                          >
                            <p
                              className={
                                darkTheme ? "headingText" : "headingTextLight"
                              }
                            >
                              {" "}
                              {item.category}
                            </p>
                          </div>
                          {width > 500 ? (
                            <>
                              <OwlCarousel className="owl-theme" {...options}>
                                {item.data.map((item, index) => {
                                  return (
                                    <div className="card text-bg-dark p-3">
                                      <img
                                        className="ItemImageCss"
                                        src={
                                          REACT_APP_BASE_URL +
                                          "file/" +
                                          item.image
                                        }
                                        alt="burger.webp"
                                      />

                                      <div className="card-body">
                                        <h5 className="card-title">
                                          {item.foodName}
                                        </h5>
                                        <div className="row align-items-start">
                                          <div className="col-9">
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <p className="fs-5">
                                                Item
                                                {/* {item.subcategory} */}
                                              </p>
                                              <p className="fs-5">Price</p>
                                            </div>
                                            {item.food.map((item, index) => {
                                              return (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <div className="mb-2">
                                                    {/* <img
                                                  src="./webImage/softdink.jpg"
                                                  className="res"
                                                  alt="..."
                                                  style={{
                                                    width: "100px",
                                                    height: "100px",
                                                  }}
                                                /> */}
                                                    <div className="mt-3">
                                                      {item.foodName}
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <div className="mb-5">
                                                      <p>Rs {item.price}</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                          {/*
                                  <div className="col">

                                    <div className="mt-2">
                                      <p>Rs 8000</p>
                                    </div>
                                  </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </OwlCarousel>
                            </>
                          ) : (
                            <div className="ItemDescription">
                              {item.data.map((item, index) => {
                                return (
                                  <div>
                                    <div className="containerImage">
                                      <img
                                        src={topicon}
                                        className="CloseButton"
                                        onClick={() => {
                                          setCategoryDescription(item);
                                          setModalOpen(true);
                                        }}
                                      />
                                      <div className="imageDescription">
                                        <img
                                          className="itemImage"
                                          src={
                                            REACT_APP_BASE_URL +
                                            "file/" +
                                            item.image
                                          }
                                          alt="fish Name"
                                        />
                                      </div>

                                      <div
                                      // className={
                                      //   darkTheme
                                      //     ? "lineMarker"
                                      //     : "lineMarkerLight"
                                      // }
                                      ></div>
                                      <div className="subMenu">
                                        <div className="subMenuHeading">
                                          <h3
                                            className={
                                              darkTheme
                                                ? "submenuItemName"
                                                : "submenuItemNameLight"
                                            }
                                          >
                                            {/* {item.subcategory} Items */}
                                          </h3>
                                          <p
                                            className={
                                              darkTheme
                                                ? "subMenuItemPrice"
                                                : "subMenuItemPriceLight"
                                            }
                                          >
                                            {/* Price */}
                                          </p>
                                        </div>
                                        {item.food.map((item, index) => {
                                          let foodNameArray =
                                            item.foodName.split("-");
                                          let itemFoodPriceArray =
                                            item.price.split(",");
                                          return (
                                            <div className="subMenuItems">
                                              <div className="imageAndName">
                                                <div
                                                  className={
                                                    darkTheme
                                                      ? "subMenuName"
                                                      : "subMenuNameLight"
                                                  }
                                                >
                                                  <span
                                                    style={{
                                                      paddingRight:
                                                        "20px !important",
                                                    }}
                                                  >
                                                    {foodNameArray[0]}
                                                  </span>
                                                  <br />
                                                  <span
                                                    style={{
                                                      marginTop: "15px",
                                                    }}
                                                  >
                                                    {foodNameArray[1]}
                                                  </span>
                                                </div>
                                              </div>
                                              <div
                                                className={
                                                  darkTheme
                                                    ? "subMenuprice"
                                                    : "subMenupriceLight"
                                                }
                                              >
                                                <span>
                                                  {itemFoodPriceArray[0]}
                                                </span>
                                                <br />
                                                <span>
                                                  {itemFoodPriceArray[1]}
                                                </span>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>

                                    {/* New added fragments  */}

                                    <div
                                      className={
                                        darkTheme
                                          ? "lineMarker"
                                          : "lineMarkerLight"
                                      }
                                    ></div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      );
                    }
                  })}
              </div>
            </>
          )
        )}

        {/* for footer heading */}
        <div className={darkTheme ? "footerMenu" : "footerMenuLight"}>
          <img
            className="FooterLogo"
            src={"/images/globe.png"}
            alt="footerImage"
          />
          <h3 className={darkTheme ? "Timing" : "TimingLight"}>
            {" "}
            Opening Time: 10 am -9pm{" "}
          </h3>
        </div>

        {/*for modal for the image pop up  */}
        {categoryDescription && (
          <div className="ModalDesign">
            <Modal
              isOpen={modalOpen}
              onRequestClose={() => setModalOpen(false)}
              style={darkTheme ? customStyles : customStyles1}
            >
              <div className="containerImage">
                <img
                  className="ModalItemName"
                  src={REACT_APP_BASE_URL + "file/" + categoryDescription.image}
                  alt="ModalimageName"
                />
                {darkTheme ? (
                  <img
                    src={closeButton}
                    className={darkTheme ? "CloseButton2" : "CloseButton2Light"}
                    onClick={() => setModalOpen(false)}
                  />
                ) : (
                  <img
                    src={closeButton2}
                    className={darkTheme ? "CloseButton2" : "CloseButton2Light"}
                    onClick={() => setModalOpen(false)}
                  />
                )}
              </div>
              <div className="ItemHeading">
                <h3
                  className={
                    darkTheme ? "subMenuNameModal" : "subMenuNameModalLight"
                  }
                >
                  {" "}
                  {categoryDescription.subcategory}
                </h3>
                {categoryDescription.food.map((item, index) => (
                  <div>
                    <h4
                      className={
                        darkTheme
                          ? "subMenuNameModalItem"
                          : "subMenuNameModalItemLight"
                      }
                    >
                      {item.foodName}
                    </h4>
                    <p
                      className={
                        darkTheme
                          ? "subMenuNameDescriptionModal"
                          : "subMenuNameDescriptionModalLight"
                      }
                    >
                      {item.description}
                    </p>
                  </div>
                ))}
              </div>
              <div className="videofield">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/6yDfvVUwI80"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </Modal>
          </div>
        )}
      </div>
      <FaCaretSquareUp
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
        className="ColorOFscrollUp"
        color="green"
        style={{ display: visible ? "inline" : "inline", zIndex: "100" }}
      />
    </div>
  );
}

const customStyles = {
  content: {
    backgroundColor: "black",
    // width: re,
    position: "relative",
    height: "100%",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    padding: "0px",
    borderRadius: "10px",
    marginTop: "10px",
    marginBotton: "10px",
  },
};

const customStyles1 = {
  content: {
    backgroundColor: "white",
    // width: 600,
    position: "relative",
    height: "100%",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    padding: "0px",
    borderRadius: "10px",
    marginTop: "10px",
    marginBotton: "10px",
  },
};

const options = {
  backgroundColor: "red",
  animateOut: "fadeOut",
  margin: 5,
  marginTop: 5,
  responsiveClass: true,
  nav: false,
  dots: false,
  autoplay: true,
  navText: [".", "."],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 4,
    },
    300: {
      items: 3.5,
    },
    500: {
      items: 3,
    },
    600: {
      items: 4,
    },
    700: {
      items: 4,
    },
    1000: {
      items: 4,
    },
  },
};
